<template>
  <v-layout>
    <v-col class="custom-content-container pt-0" cols="12" :md="12" :sm="12">
      <v-layout class="d-block">
        <v-row>
          <v-col class="iv-custom-field" cols="9" md="9" sm="9">
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid>
                <v-row v-if="lodash.isEmpty(supplier)">
                  <v-col class="pt-0" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="company_type"
                      >Primary Contact</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="3" sm="12">
                    <v-select
                      id="salutation"
                      v-model.trim="detail.salutation"
                      :items="salutationList"
                      dense
                      filled
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      placeholder="Salutation"
                      solo
                      flat
                      color="cyan"
                    ></v-select>
                  </v-col>

                  <v-col class="pt-0" md="3" sm="12">
                    <v-text-field
                      id="first_name"
                      :class="{
                        'v-text-field--enclosed error--text ':
                          !detail.first_name,
                      }"
                      v-model.trim="detail.first_name"
                      :rules="[
                        validateRules.required(detail.first_name, 'first name'),
                        validateRules.minLength(
                          detail.first_name,
                          'first name',
                          2
                        ),
                        validateRules.maxLength(
                          detail.first_name,
                          'first name',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="First Name"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                      v-on:keyup="appendDisplayName()"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pt-0" md="3" sm="12">
                    <v-text-field
                      id="last_name"
                      v-model.trim="detail.last_name"
                      :rules="[
                        validateRules.minLength(
                          detail.last_name,
                          'last name',
                          2
                        ),
                        validateRules.maxLength(
                          detail.last_name,
                          'last name',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Last Name"
                      solo
                      flat
                      color="cyan"
                      v-on:keyup="appendDisplayName()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500 required mt-4"
                      for="company_name"
                      :class="{ required: detail.primary }"
                      >Company Name</label
                    >
                  </v-col>
                  <v-col md="9" sm="12">
                    <TextValidateInput
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :rules="company_rules()"
                      id="company-name"
                      placeholder="Company Name"
                      :validationField="{
                        url_type: 'supplier',
                        filter_type: 'contact',
                        field: 'company_name',
                      }"
                      :class="{ required: !detail.company_name }"
                      :parent-id="detail.id"
                      :current-id="detail.id"
                      show-dropdown
                      v-model="detail.company_name"
                    ></TextValidateInput>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500 required mt-4"
                      for="display_name"
                      >Display Name</label
                    >
                  </v-col>
                  <v-col class="" md="4" sm="12">
                    <TextValidateInput
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :rules="[
                        validateRules.required(
                          detail.display_name,
                          'Display Name'
                        ),
                      ]"
                      id="display-name"
                      placeholder="Display Name"
                      :validationField="{
                        url_type: 'supplier',
                        filter_type: 'contact',
                        field: 'display_name',
                      }"
                      :parent-id="detail.id"
                      :current-id="detail.id"
                      show-dropdown
                      v-model="detail.display_name"
                    ></TextValidateInput>
                    <!--  <label class="font-size-16 font-weight-500" for="salutation"
                        >Salutation</label
                      > -->
                    <!-- <v-text-field
                      id="company_name"
                      v-model.trim="detail.display_name"
                      dense
                      filled
                      :rules="[
                        validateRules.required(
                          detail.display_name,
                          'Display Name'
                        ),
                      ]"
                      label="Display Name"
                      solo
                      flat
                      color="cyan"
                      v-on:blur="isFocusedD = false"
                      v-on:focus="isFocusedD = true"
                      v-on:keyup="
                        getCustomerListDisplay(
                          detail.display_name,
                          'display_name'
                        )
                      "
                    ></v-text-field> -->
                    <!--  <template v-if="isFocusedD">
                      <div
                        class="suggested-content elevation-2"
                        v-if="suggestedItems2 && suggestedItems2.length"
                      >
                        <template v-for="(item, index) in suggestedItems2">
                          <v-list-item :key="index" class="py-2">
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.display_name"
                              ></v-list-item-title>
                            </v-list-item-content>
                            <span class="red--text px-3 pt-1"
                              >Already exists
                            </span>
                          </v-list-item>
                          <v-divider
                            class="my-0"
                            :key="`index--${index}`"
                          ></v-divider>
                        </template>
                      </div>
                    </template> -->
                  </v-col>

                  <v-col class="mt-3" md="1" sm="12" style="text-align: end">
                    <label class="font-size-16 font-weight-500" for="uen"
                      >UEN</label
                    >
                  </v-col>
                  <v-col class="mt-3" md="4" sm="12">
                    <!--  <TextValidateInput
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :rules="[validateRules.minLength(detail.uen, 'UEN', 3)]"
                      id="uen"
                      placeholder="UEN"
                      :validationField="{
                        url_type: 'supplier',
                        filter_type: 'contact',
                        field: 'uen',
                      }"
                      :parent-id="detail.id"
                      :current-id="detail.id"
                      show-dropdown
                      v-model="detail.uen"
                    ></TextValidateInput> -->
                    <v-text-field
                      id="uen"
                      v-model.trim="detail.uen"
                      dense
                      filled
                      label="UEN"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>
                    <!-- v-on:keyup="getCustomerListUen(detail.uen, 'uen')" -->
                  </v-col>
                </v-row>

                <template v-for="(cdata, index) in emailDetails">
                  <v-row class="contact-detail-row" :key="'email-' + index">
                    <v-col md="3" sm="12">
                      <label
                        class="font-size-16 font-weight-500"
                        :for="`email-${index}`"
                        >Email</label
                      >
                    </v-col>

                    <v-col md="4">
                      <!-- validateRules.required(cdata.value, 'email'),  class="required-field"-->
                      <v-text-field
                        :id="`email-${index}`"
                        v-model.trim="cdata.value"
                        :rules="[
                          validateRules.validEmail(cdata.value, 'email'),
                          validateRules.minLength(cdata.value, 'email', 2),
                          validateRules.maxLength(cdata.value, 'email', 100),
                        ]"
                        dense
                        filled
                        placeholder="Email"
                        solo
                        flat
                        color="cyan"
                      ></v-text-field>
                    </v-col>

                    <v-col class="text-right" md="1" sm="12">
                      <label class="font-size-16 font-weight-500" for="website"
                        >Website</label
                      >
                    </v-col>
                    <v-col md="4" sm="12">
                      <TextField
                        id="website"
                        dense
                        filled
                        placeholder="Website"
                        solo
                        flat
                        counter="50"
                        v-model="detail.website"
                        color="cyan"
                        :maxlength="50"
                      />
                      <!--  <v-text-field
                        id="website"
                        v-model.trim="detail.website"
                        :rules="[
                          validateRules.minLength(detail.website, 'website', 3),
                          validateRules.maxLength(
                            detail.website,
                            'website',
                            50
                          ),
                        ]"
                        dense
                        filled
                        placeholder="Website"
                        solo
                        flat
                        color="cyan"
                        class="required-field"
                      ></v-text-field> -->
                    </v-col>
                  </v-row>
                </template>
                <template v-for="(cdata, index) in contactDetails">
                  <v-row class="contact-detail-row" :key="'contact-' + index">
                    <v-col md="3" sm="12">
                      <label
                        class="font-size-16 font-weight-500"
                        for="display_name"
                        >Supplier phone number</label
                      >
                    </v-col>

                    <v-col md="4" sm="12">
                      <!-- <label
                          class="font-size-16 font-weight-500 required"
                          :for="`contact-${index}`"
                          >Phone Number</label
                        >
                      
                      required-->
                      <PhoneTextField
                        :id="`contact-${index}`"
                        v-model="cdata.value"
                        :value="cdata.value"
                      >
                      </PhoneTextField>
                    </v-col>

                    <v-col class="text-right" md="1" sm="12">
                      <label class="font-size-16 font-weight-500" for="website"
                        >Supplier fax</label
                      >
                    </v-col>
                    <v-col class="" md="4" sm="12">
                      <PhoneTextField
                        v-model="detail.company_fax"
                        label="Supplier fax"
                      >
                      </PhoneTextField>
                    </v-col>
                  </v-row>
                </template>
                <v-row>
                  <v-col class="" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="display_name"
                      >Supplier Remark
                    </label>
                  </v-col>

                  <v-col class="" md="9" sm="12">
                    <v-textarea
                      v-model="detail.supplier_remark"
                      auto-grow
                      dense
                      filled
                      color="cyan"
                      placeholder="Supplier Remark"
                      solo
                      flat
                      row-height="15"
                      v-on:keypress="(e) => manageLimit(e)"
                      v-on:paste="(e) => onPaste(e)"
                    ></v-textarea>
                  </v-col>
                  <v-col md="12" class="text-right"
                    >{{
                      detail.supplier_remark
                        ? detail.supplier_remark.length
                        : 0
                    }}/200</v-col
                  >
                </v-row>
                <v-row>
                  <CustomForm
                    v-if="!detail.id"
                    :module="'supplier'"
                    :module-id="detail.id"
                    @updated="getFieldCustomDate"
                  />
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </v-col>
          <v-col
            class="iv-custom-field"
            cols="3"
            md="3"
            sm="3"
            v-on:mouseleave="imageTemplate = false"
            v-on:mouseover="imageTemplate = true"
          >
            <template>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="getProfileImage ? getProfileImage : $defaultProfileImage"
                max-height="200"
                max-width="200"
                height="200px"
                width="200px"
                class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
              >
              </v-img>
              <div class="margin-auto">
                <v-btn
                  class="my-4 custom-bold-button width-100"
                  outlined
                  small
                  v-on:click="selectImage"
                  color="cyan"
                  :loading="imageLoading"
                  :disabled="imageLoading"
                >
                  <template v-if="getProfileImage">Change Image</template>
                  <template v-else>Upload Image</template>
                </v-btn>
                <div class="d-none">
                  <v-file-input
                    ref="uploadImage"
                    v-on:change="updateProfileImage"
                    :rules="imageRules"
                    accept="image/png, image/jpeg, image/jpg"
                  ></v-file-input>
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row> </v-row>
      </v-layout>
    </v-col>
    <template v-if="false && !isDialog">
      <v-col
        class="custom-sidebar-container grey lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </template>
  </v-layout>
</template>

<script>
import CustomForm from "@/view/pages/custom-field-v2/CustomForm.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import SupplierMixin from "@/core/plugins/supplier-mixin";
import { GET, QUERY } from "@/core/services/store/request.module";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import TextValidateInput from "@/view/pages/partials/TextValidateInput.vue";
export default {
  name: "contact-details",
  title: "Supplier",
  mixins: [CommonMixin, ValidationMixin, SupplierMixin, FileManagerMixin],
  props: {
    supplier: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupList: [],
      pageLoading: false,
      lead: 0,
      proposal: 0,
      timeoutLimit: 500,
      timeout: null,
      autocompleteList: new Array(),
      autocompleteLoading: false,
      isFocused: false,
      isFocusedU: false,
      isFocusedN: false,
      isFocusedD: false,
      suggestedItems: [],
      suggestedItems2: [],
      suggestedItems3: [],
      suggestedItems4: [],
      suggestedItems5: [],
      suggestedItems6: [],

      detail: {
        company_email: null,
        company_number: null,
        company_fax: null,
        supplier_remark: null,
        primary: false,
        salutation: "mr",
        first_name: null,
        last_name: null,
        company_name: null,
        display_name: null,
        profile_logo: null,
        group_id: null,
        phone: null,
        website: null,
        security_code: null,
        unit_number: null,
        address_line_1: null,
        address_line_2: null,
        postal_code: null,
        country: null,
        state: null,
        city: null,
        details: [],
      },
      imageLoading: false,
      emailDetails: [
        {
          title: "main",
          value: null,
          type: 1,
          id: null,
        },
      ],
      contactDetails: [
        {
          title: "main",
          value: null,
          type: 2,
          id: null,
        },
      ],
      imageTemplate: false,
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    perfectScrollHeight(param) {
      console.log({ param });
    },
    "detail.primary"() {
      /*  if (this.detail.primary) { */
      this.detail.display_name = this.detail.company_name;
      /* } */
    },
    "detail.company_name"() {
      /*  if (this.detail.primary) { */
      /*   alert(32) */
      this.detail.display_name = this.detail.company_name;
      /*   } */
    },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("saveContactDetail", param);
      },
    },
    emailDetails: {
      deep: true,
      handler() {
        this.updateDetails();
      },
    },
    contactDetails: {
      deep: true,
      handler() {
        this.updateDetails();
      },
    },
  },
  methods: {
    getFieldCustomDate($event) {
      console.log($event);
    },
    clearAddress() {
      const _this = this;
      _this.detail.postal_code = null;
      _this.detail.address_line_1 = null;
      _this.detail.address_line_2 = null;
      _this.detail.unit_number = null;
    },
    appendDisplayName() {
      const result = [];
      const { first_name, last_name } = this.detail;
      if (first_name) {
        result.push(first_name);
      }
      if (last_name) {
        result.push(last_name);
      }

      this.detail.display_name = result.join(" ");
      console.log(this.detail.display_name);
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.detail.supplier_remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.detail.supplier_remark = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (
        this.detail.supplier_remark &&
        this.detail.supplier_remark.length > 199
      ) {
        e.preventDefault();
      }
    },

    company_rules() {
      const rules = [];

      rules.push(
        this.validateRules.required(this.detail.company_name, "Company name")
      );

      rules.push(
        this.validateRules.minLength(
          this.detail.company_name,
          "display name",
          2
        )
      );

      rules.push(
        this.validateRules.maxLength(
          this.detail.company_name,
          "display name",
          100
        )
      );

      return rules;
    },

    getCustomerList(search_key) {
      this.detail.display_name = search_key;
      this.suggestedItems = [];
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "search-company",
          data: {
            type: "customers",
            name: "company_name",
            value: search_key ? search_key : "null",
          },
        })
        .then((data) => {
          this.suggestedItems = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCustomerListDisplay(search_key) {
      this.suggestedItems2 = [];
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "search-company",
          data: {
            type: "customers",
            name: "display_name",
            value: search_key ? search_key : "null",
          },
        })
        .then((data) => {
          this.suggestedItems2 = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },

    selectGoogleAddress(index) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        let place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "google-api/place-detail",
            data: { "place-id": place_id },
          })
          .then((response) => {
            _this.formatAddress(response.data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.clearAddress();
            _this.autocompleteList = new Array();
            _this.autocompleteLoading = false;
          });
      }
    },
    formatAddress(param) {
      const _this = this;
      try {
        _this.autocompleteLoading = true;
        _this
          .formatGPSAddress(param)
          .then((result) => {
            if (_this.lodash.isEmpty(param) === false) {
              _this.detail.postal_code = result.zip_code;
              _this.detail.address_line_1 = result.street_1;
              _this.detail.address_line_2 = result.street_2;
              _this.detail.unit_number = result.unit_no;
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.autocompleteLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.detail.postal_code && _this.detail.postal_code.length == 6) {
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.detail.postal_code },
              })
              .then((response) => {
                _this.autocompleteList = response.data;
              })
              .catch((error) => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        } else {
          _this.autocompleteList = new Array();
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateDetails() {
      const _this = this;
      _this.detail.details = [];
      for (let i = 0; i < _this.emailDetails.length; i++) {
        if (_this.emailDetails[i]) {
          delete _this.emailDetails[i].default;
          _this.detail.details.push(_this.emailDetails[i]);
        }
      }
      for (let z = 0; z < _this.contactDetails.length; z++) {
        if (_this.emailDetails[z]) {
          delete _this.emailDetails[z].default;
          _this.detail.details.push(_this.contactDetails[z]);
        }
      }
    },
    pushEmailDetail() {
      this.emailDetails.push({
        title: "main",
        value: null,
        type: 1,
        id: null,
      });
    },
    removeEmailDetail(index) {
      this.emailDetails.splice(index, 1);
    },
    pushContactDetail() {
      this.contactDetails.push({
        title: "main",
        value: null,
        type: 2,
        id: null,
      });
    },
    removeContactDetail(index) {
      this.contactDetails.splice(index, 1);
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.detail.profile_logo = _this.lodash.head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    getGroupList() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "supplier/group-list",
        })
        .then(({ data }) => {
          _this.groupList = data.group_list;
          _this.detail.security_code = data.security_code;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    editDetails() {
      const _this = this;

      //console.log(_this.supplier,"_this.supplier");

      _this.defaultPerson = _this.getDefaultPerson(_this.supplier);
      // console.log(_this.defaultPerson, " _this.defaultPerson");
      _this.emailDetails = _this.getEmailDetails(_this.supplier);
      // console.log( _this.emailDetails," _this.emailDetails");
      _this.contactDetails = _this.getPhoneDetails(_this.supplier);

      _this.detail.primary = _this.supplier.primary;

      this.emailDetails.push({
        title: "main",
        value: _this.supplier.company_email,
        type: 1,
        id: null,
      });
      this.contactDetails.push({
        title: "main",
        value: _this.supplier.company_number,
        type: 2,
        id: null,
      });

      //  _this.detail.salutation = _this.defaultPerson.title;
      // _this.detail.first_name = _this.defaultPerson.first_name;
      // _this.detail.last_name = _this.defaultPerson.last_name;

      _this.detail.id = _this.supplier.id;
      _this.detail.website = _this.supplier.website;
      _this.detail.company_fax = _this.supplier.company_fax;
      _this.detail.supplier_remark = _this.supplier.supplier_remark;
      _this.detail.uen = _this.supplier.uen;
      _this.detail.company_name = _this.supplier.company_name;
      _this.detail.display_name = _this.supplier.display_name;
      _this.detail.group_id = _this.supplier.group_id;
      _this.detail.phone = _this.supplier.phone;
      _this.detail.website = _this.supplier.website;
      _this.detail.unit_number = _this.supplier.unit_number;
      _this.detail.address_line_1 = _this.supplier.address_line_1;
      _this.detail.address_line_2 = _this.supplier.address_line_2;
      _this.detail.postal_code = _this.supplier.postal_code;
      _this.detail.country = _this.supplier.country;
      _this.detail.state = _this.supplier.state;
      _this.detail.city = _this.supplier.city;
      _this.detail.profile_logo = _this.supplier.profile_logo;
    },
    getLead() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "leads/" + _this.lead,
        })
        .then(({ data }) => {
          let fullname = data.name ? data.name.split(" ") : [];
          _this.detail.first_name = fullname[0];
          _this.detail.last_name = fullname[1];
          _this.detail.company_name = data.company;
          _this.detail.display_name = data.company;
          _this.detail.phone = data.phone_number;
          _this.detail.website = data.website;
          _this.detail.postal_code = data.zip;
          _this.detail.country = data.country;
          _this.detail.state = data.state;
          _this.detail.city = data.city;
          _this.detail.address_line_1 = data.address;

          _this.emailDetails[0] = new Object({
            title: "main",
            value: data.email,
            type: 1,
            id: null,
          });

          _this.contactDetails[0] = new Object({
            title: "main",
            value: data.phone_number,
            type: 2,
            id: null,
          });

          _this.updateDetails();
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getProposal() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "proposals/" + _this.proposal,
        })
        .then(({ data }) => {
          let fullname = data.name ? data.name.split(" ") : [];
          _this.detail.first_name = fullname[0];
          _this.detail.last_name = fullname[1];
          _this.detail.company_name = data.company;
          _this.detail.display_name = data.company;
          _this.detail.phone = data.phone_number;
          _this.detail.website = data.website;
          _this.detail.postal_code = data.zip;
          _this.detail.country = data.country;
          _this.detail.state = data.state;
          _this.detail.city = data.city;
          _this.detail.address_line_1 = data.address;

          _this.emailDetails[0] = new Object({
            title: "main",
            value: data.email,
            type: 1,
            id: null,
          });

          _this.contactDetails[0] = new Object({
            title: "main",
            value: data.phone_number,
            type: 2,
            id: null,
          });

          _this.updateDetails();
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getGroupList();
    if (_this.lodash.isEmpty(_this.supplier) === false) {
      _this.editDetails();
    }

    _this.lead = _this.lodash.toSafeInteger(_this.$route.query.lead);
    if (_this.lead > 0) {
      _this.getLead();
    }

    _this.proposal = _this.lodash.toSafeInteger(_this.$route.query.proposal);
    if (_this.proposal > 0) {
      _this.getProposal();
    }
  },
  components: {
    CustomForm,
    PhoneTextField,
    TextValidateInput,
  },
  computed: {
    getProfileImage() {
      return this.lodash.isEmpty(this.detail.profile_logo) === false
        ? this.detail.profile_logo.file.url
        : null;
    },
  },
};
</script>
